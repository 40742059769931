* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

body {
  padding: 0 2rem;
  background-color: #fafafa;
}
html {
  overflow-y: scroll;
}
/* NAVBAR */
.navbar {
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  gap: 4rem 0;
  margin: 2rem 0;
}

.navbar nav {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 4rem;
}

.navbar nav a {
  color: black;
  text-decoration: none;
  font-weight: 900;
  transition: 0.3s;
  cursor: pointer;
}

.navbar nav a:hover {
  color: #0077c0;
}

.navbar img {
  cursor: pointer;
}

/* SEARCHBOX */
.person-list {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 1rem;
}
.person-img {
  width: 40px;
  height: 40px;
  border-radius: 25%;
}
/* QUOTES CARDS */
.cards-container {
  padding: 2rem 0;
  width: 100%;
}

.cards-container h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.card-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Social cards */
.button {
  width: 100%;
}

.button-btn {
  font-size: 16px;
  padding: 8px 16px;
  background: #153448;
  color: #fff;
  border: none;
  border-radius: 4px;
  transition: 0.4s;
}
.button-btn:hover {
  background: #3c5b6f;
}

.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  border: 1px solid #2e4ead;
  padding-right: 40px;
  color: #fff;
}
.input-search::placeholder {
  color: #2e4ead;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #2e4ead;
  background-color: transparent;
  pointer-events: painted;
}
.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid #2e4ead;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid #2e4ead;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.banner {
  width: 100%;
  height: 30vh;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  color: #fff;
  transition: flex 0.4s ease;
  position: relative;
  cursor: pointer;
}

.section .overlay {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  transition: background-color 0.8s ease;
}

.section .content {
  z-index: 2;
  text-align: center;
}

.section:hover {
  flex: 2;
}

.section:hover .overlay {
  background-color: rgba(0, 0, 0, 0.95);
}

button {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 50px;
  color: #333;
  background: #fff;
  cursor: pointer;
}

/* SOCIAL MEDIA SHARE */

.btn-share {
  --btn-color: #275efe;
  position: relative;
  padding: 16px 32px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: white;
  background: none;
  border: none;
  outline: none;
  overflow: hidden;
  cursor: pointer;
  filter: drop-shadow(0 2px 8px rgba(39, 94, 254, 0.32));
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 150px;
  margin: 0 auto;
}
.btn-share::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: var(--btn-color);
  border-radius: 24px;
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.btn-share .btn-text,
.btn-share .btn-icon {
  display: inline-flex;
  vertical-align: middle;
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.btn-share .btn-text {
  transition-delay: 0.05s;
}
.btn-share .btn-icon {
  margin-left: 8px;
  transition-delay: 0.1s;
}
.btn-share .social-icons {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform: translateY(-50%);
}
.btn-share .social-icons li {
  flex: 1;
}
.btn-share .social-icons li a {
  display: inline-flex;
  vertical-align: middle;
  transform: translateY(55px);
  transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #fff;
}

.btn-share:hover {
  border-radius: 0;
}
.btn-share .social-icons li a:hover {
  opacity: 0.5;
}
.btn-share:hover::before {
  transform: scale(1.2);
}
.btn-share:hover .btn-text,
.btn-share:hover .btn-icon {
  transform: translateY(-55px);
}
.btn-share:hover .social-icons li a {
  transform: translateY(0);
}
.btn-share:hover .social-icons li:nth-child(1) a {
  transition-delay: 0.15s;
}
.btn-share:hover .social-icons li:nth-child(2) a {
  transition-delay: 0.2s;
}
.btn-share:hover .social-icons li:nth-child(3) a {
  transition-delay: 0.25s;
}

/* LIKE BUTTON */
.heart {
  width: 100px;
  height: 100px;
  background: url('https://cssanimation.rocks/images/posts/steps/heart.png') no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
}
.heart.is-active {
  transition-duration: 1s;
  background-position: -2800px 0;
}

.social {
  display: flex;
  flex-direction: column;
}

.social-share {
  display: flex;
  justify-content: flex-end;
  gap: 0 0.5rem;
  position: absolute;
  bottom: 2rem;
  right: 1rem;
}
/* AUTHOR PAGE */
.author-header {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem 0;
}

.author-header h1,
.author-quotes h1 {
  border-bottom: 1px solid black;
  line-height: 4rem;
}

.author-info {
  display: flex;
  height: 60vh;
  width: 60vw;
  display: flex;
}

.author-info img,
.info-text {
  border: 1px solid black;
  height: 100%;
  width: 50%;
}

.author-info p {
  line-height: 2rem;
  max-height: 100%;
  /* Quotes div'inin maksimum yüksekliği */
  overflow-y: auto;
  /* Dikey kaydırma çubuğu ekler */
}

.info-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

/* QUOTE CARD */
.animated-border-quote {
  display: flex;
  margin: 1em;
  width: 300px;
  overflow: hidden;
  height: 350px;
}

.animated-border-quote blockquote {
  background-color: #fff;
  border: solid 2px #757575;
  display: inline-block;
  margin: 0;
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  height: 100%;
  width: 100%;
}
.animated-border-quote blockquote:before {
  animation: clockwise 30s infinite linear;
  background-color: #fff;
  bottom: 10%;
  content: '';
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 10%;
}
.animated-border-quote blockquote:after {
  animation: counter 30s infinite linear;
  background-color: #fff;
  bottom: 10%;
  content: '';
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 10%;
}
.animated-border-quote blockquote cite {
  display: block;
  font-style: italic;
  text-align: right;
}
.animated-border-quote blockquote cite:before {
  content: '- ';
}
.animated-border-quote blockquote > * {
  z-index: 1;
}
@keyframes clockwise {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes counter {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.author-quote-card {
  height: 200px;
}

.author-quotes {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quote-card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem 0;
}

/* AUTHORS PAGE */
.authors-header {
  text-align: center;
  margin-top: 2rem;
}

.AlphabetNav {
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-flow: wrap row;
  padding: 0.5rem;
  background: ghostwhite;
}
.AlphabetNav a {
  display: flex;
  justify-content: center;
  padding: 0.5em;
  border-radius: 8px;
  font-size: 1.2em;
  line-height: 1;
  font-weight: 500;
  text-decoration: none;
  color: darkgray;
  width: 8vw;
}
.AlphabetNav a:hover {
  background: #ddd;
  color: #444;
}
.AlphabetNav .active {
  background: #f46353;
  color: white;
}

.see-more {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.btn-more {
  margin: 0 auto;
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-more:focus {
  color: #171e29;
}

.btn-more:hover {
  border-color:  #0077c0;
  color:  #0077c0;
  fill:  #0077c0;
}

@media (min-width: 768px) {
  .btn-more {
    min-width: 170px;
  }
}
/* Contact List */
.ContactList {
  padding: 10em 2rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Contact {
  display: flex;
  align-items: center;
  width: 90%;
}
.ContactList .Contact {
  cursor: pointer;
  margin-bottom: 1.5rem;
}
.Contact-avatar {
  margin: 0 1em 0 0;
  border-radius: 50%;
  background: #a7c031;
  transition: transform 0.3s ease-in-out;
}

.card-header img {
  transition: transform 0.3s ease-in-out;
}

.Contact-avatar:hover,
.card-header img:hover {
  transform: scale(1.4);
}

.Contact-name {
  margin: 0;
  font-size: 1.3em;
  font-weight: 500;
  color: darkgray;
}

@media (min-width: 800px) {
  .AlphabetNav {
    top: 0;
    display: flex;
    align-items: flex-start;
    flex-flow: nowrap row;
    justify-content: space-around;
    width: 100%;
    height: auto;
    background: ghostwhite;
  }
  .AlphabetNav a {
    margin: 0.5em 0;
    padding: 0.5em 0;
  }
  .ContactList {
    padding-top: 6rem;
  }
  .Contact {
    width: calc(100% * 1 / 3 - 2rem);
  }
}

/* TOPICS PAGE */
a {
  text-decoration: none;
  color: #0033cc;
}
ul,
li {
  list-style: none;
  vertical-align: middle;
}
ul.list {
  padding-left: 20px;
}
.menu {
  position: fixed;
  right: 0;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  z-index: 2;
  top: 58px;
}
.list li {
  border-bottom: 1px solid #eee;
  line-height: 44px;
  height: 44px;
  color: darkgray;
}

.list li a {
  text-decoration: none;
  color: darkgray;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;
}
.list .divider {
  font-size: 20px;
  font-weight: bold;
  box-shadow: -60px 0 0 #fafafa;
  line-height: 28px;
  height: 28px;
  color: black;
}

header {
  line-height: 44px;
  height: 44px;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  position: absolute;
  text-align: center;
  box-shadow: 0 1px 0 #fafafa;
  z-index: 10;
}
#scrolling {
  padding-top: 44px;
}

#scrolling h1 {
  text-align: center;
  margin-bottom: 2rem;
}

/* RESPONSIVE CSS */
/* MOBILE SCREEN */
@media screen and (max-width: 767px) {
  .banner {
    display: none;
  }

  .navbar-logo {
    width: 200px;
  }

  .navbar {
    flex-direction: column;
    height: 30vh;
    gap: 1rem 0;
    margin: 1rem 0;
  }

  .navbar nav a {
    font-size: 14px;
  }

  .cards-container {
    padding: 0;
  }

  .ContactList {
    padding: 2rem 2rem 0;
  }

  #scrolling {
    padding: 0;
  }

  .author-info {
    width: 100%;
    height: auto;
    flex-direction: column;
  }

  .info-text {
    height: 70%;
    width: 100%;
    padding: 1rem 1rem;
  }

  .page-container {
    gap: 1rem 0;
  }

  .author-info img {
    width: 100%;
  }
}

#scrolling h1 {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.authors-header {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.2rem;
}

/* TABLET SCREEN */

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-logo {
    width: 200px;
  }

  .navbar nav a {
    font-size: 14px;
  }

  .navbar {
    height: 8vh;
  }

  .navbar nav {
    padding: 0 1rem;
  }

  .ContactList {
    padding: 2rem 2rem 0;
  }

  #scrolling {
    padding: 0;
  }

  .author-info {
    width: 100%;
  }

  .author-quote-card {
    height: 200px !important;
  }
}
